// This is the homepage.

import React from "react"
//import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import img1 from '../images/The-Federal-Coffee-Palace.jpg';
import img2 from '../images/haka-moarionette-512x1024.jpg';
import img3 from '../images/ristorante.jpg';
import img4 from '../images/maori-chief-512x1024.jpg';
import img5 from '../images/saddam15-1024x553.jpeg';
import img6 from '../images/faema-e61.jpg';



const Heading1 = styled.h1`
    font-size: 1.8em;
    padding: 10px;
    text-align: center;
    width: 100%;
    min-height: 85px;
    margin: auto;
    font-weight:600;
  
`


const Wrapper = styled.div`
    width: 100%;
    background-position: center;
    padding: 10px;
    padding: 40px 40px 40px 40px;
`

const ContentWrapper = styled.div`
    text-align: center;
    padding: 20px 10px 20px 10px;
    margin: auto;
`

const Content = styled.div`

    font-size: 1.3em;
    line-height: 30px;
    text-align: center;
    padding: 20px 0px 20px 0px;
`

const Images = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 20px 0px 20px 0px;
    overflow-x: hidden;
`

class BlogIndex extends React.Component {
  render() {

    return (

      <Layout location={this.props.location}>
        <SEO title="Timeline" />
         <Wrapper><Heading1>The story so far</Heading1>
         <ContentWrapper>
         <Content>Bailey Coffee hails from the second wave of serious espresso, founded Devonport Auckland  New Zealand </Content>
            <Images> <img src={img6} alt="Faemae 61"/></Images>
            <Images> <img src={img1} alt="The Federal Coffee Palace"/></Images>
            <Images> <img src={img2} alt="Haka Moarionette"/></Images>
            <Images> <img src={img3} alt="Ristorante"/></Images>
            <Images> <img src={img4} alt="maori chief"/></Images>
            <Images> <img src={img5} alt="Saddam"/></Images>

            </ContentWrapper>
          </Wrapper>
      </Layout>
    )
  }
}

export default BlogIndex